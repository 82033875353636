import React from "react";
import { Card, CardContent, CardActions, Typography, Button, ListItemText, ListItem, List, useMediaQuery, useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTimeslotDrawer } from "../routes/landing_page/salesTimeslot/TimeSlotProvider";

interface Props {
    id: string;
}

const PricingCard: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("lg"));
    const { setOpenTimeslotDrawer } = useTimeslotDrawer();

    const data = [
        { label: "Aanbestedingen NL + BE" },
        { label: "5 gebruikers + sector naar keuze" },
        { label: "Onbeperkt zoeken" },
        { label: "10+ jaar verrijkte aanbestedingsinformatie" },
        { label: "Persoonlijke overzichten en exports" },
        { label: "Verrijkte organisatieprofielen" },
        { label: "Doorzoek dagelijks honderden documenten" },
        { label: "Automatiseer jouw zoekopdrachten" },
        { label: "Vind alles op één plek" },
    ];

    return (
        <Card raised sx={{ width: "450px", borderRadius: "16px" }}>
            <CardContent style={{ position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        width: "180%",
                        top: 0,
                        left: "50%",
                        transform: noMobile ? "translate(-50%, -20%)" : "translate(-50%, -10%)",
                    }}
                >
                    <svg width="100%" height="100%" viewBox="0 0 1921 453" xmlns="http://www.w3.org/2000/svg">
                        <g transform="matrix(1,0,0,1,0.296415,-477.687)">
                            <g transform="matrix(1,0,0,0.95792,0,20.1009)">
                                <path
                                    d="M1919.97,477.687C1916.54,738.549 1487.63,949.807 959.836,949.807C432.038,949.807 3.135,738.549 -0.296,477.687L1919.97,477.687Z"
                                    fill="#5f4aea14"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <span
                    style={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#5f4aea80",
                    }}
                >
                    {"vanaf"}
                </span>
                <Typography
                    gutterBottom
                    sx={{
                        fontWeight: 500,
                        fontSize: noMobile ? "4.4em" : "2rem !important",
                        marginTop: noMobile ? "-4px" : "0.1em",
                        marginBottom: noMobile ? "0.35em" : "0.1em",
                    }}
                    component="div"
                    textAlign="center"
                >
                    {"€98"}
                </Typography>
                <span
                    style={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#5f4aea80",
                        marginTop: noMobile ? "-30px" : "-5px",
                    }}
                >
                    {"per maand"}
                </span>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "32px" }}>
                    <List style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>
                        {data.map((item) => (
                            <ListItem key={item.label} sx={{ py: 0, minHeight: "32px", width: "fit-content" }}>
                                <div
                                    style={{
                                        minWidth: "24px",
                                        minHeight: "24px",
                                        borderRadius: "8px",
                                        marginRight: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Check style={{ color: "green", width: "24px", height: "24px" }} />
                                </div>
                                <ListItemText
                                    primary={item.label}
                                    primaryTypographyProps={{ fontSize: "1.1rem", fontWeight: "300", textAlign: "left", width: "fit-content" }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </CardContent>
            <CardActions sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "8px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOpenTimeslotDrawer(true);
                    }}
                >
                    plan een demo
                </Button>
            </CardActions>
        </Card>
    );
};

export default PricingCard;
